// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// ** Custom Components
import Text from "components/typography/Text";

// ** Custom Hooks
import useVipStatusText from "hooks/useVipStatusText";

// ** Utils & Helpers
import staticFileHelper from "helpers/staticFileHelper";

// ** Styled Components
import { StyledUnverified, StyledImage } from "./styles";

// #####################################################

const VipStatus = ({
	noPad,
	status,
	whiteMode,
	height,
	showUnverified,
	marginLeft,
	marginLeftUnverified,
	marginBottom,
	mrVip,
	withText,
	leftPad,
}) => {
	const { t } = useTranslation("common");

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	whiteMode = isDarkMode ? !whiteMode : whiteMode;

	const alt = useVipStatusText(status);
	const renderStatus = () => {
		switch (status) {
			case 1:
				return showUnverified ? (
					<StyledUnverified
						$marginLeftUnverified={marginLeftUnverified}
					>
						{t("common:statusVip.1")}
					</StyledUnverified>
				) : null;
			case 2:
				return (
					<StyledImage
						src={
							whiteMode
								? staticFileHelper(
										`/img/icon/vip_icon/svg/zweryfikowany_dark.svg`,
										"vip-icons"
								  )
								: staticFileHelper(
										`/img/icon/vip_icon/svg/zweryfikowany_light.svg`,
										"vip-icons"
								  )
						}
						height={height || 16}
						marginLeft={marginLeft}
						marginBottom={marginBottom}
						alt={alt}
					/>
				);
			case 3:
			case 4:
				return (
					<StyledImage
						src={
							whiteMode
								? staticFileHelper(
										`/img/icon/vip_icon/svg/vip_dark.svg`,
										"vip-icons"
								  )
								: staticFileHelper(
										`/img/icon/vip_icon/svg/vip_light.svg`,
										"vip-icons"
								  )
						}
						height={height || 16}
						alt={alt}
						marginLeft={marginLeft}
						marginBottom={marginBottom}
					/>
				);
			case 5:
				return (
					<StyledImage
						src={
							whiteMode
								? staticFileHelper(
										`/img/icon/vip_icon/svg/supervip_dark.svg`,
										"vip-icons"
								  )
								: staticFileHelper(
										`/img/icon/vip_icon/svg/supervip_light.svg`,
										"vip-icons"
								  )
						}
						height={height || 16}
						alt={alt}
						marginLeft={marginLeft}
						marginBottom={marginBottom}
					/>
				);
			default:
				return null;
		}
	};

	// #####################################################

	return (
		<Text px={noPad ? undefined : 1} pl={leftPad} mr={mrVip}>
			{withText ? `${alt} ` : null}
			{renderStatus()}
		</Text>
	);
};

// #####################################################

VipStatus.defaultProps = {
	status: false,
	darken: true,
	marginLeft: 0,
};

VipStatus.propTypes = {
	status: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
	]),
	darken: PropTypes.bool,
	marginLeft: PropTypes.number,
};

VipStatus.displayName = "VipStatus";

export default VipStatus;

// #####################################################
