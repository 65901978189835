import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Img from "components/base/Img";
import Block from "components/layout/Block";

const Image = styled(
	({
		blur,
		fluid,
		width,
		height,
		realWidth,
		realHeight,
		circle,
		vaMiddle,
		widthRaw,
		video,
		iconVideoBig,
		...props
	}) => {
		if (video) {
			return (
				<Block {...props} relative>
					<Img {...props} />
				</Block>
			);
		}

		return <Img width={realWidth} height={realHeight} {...props} />;
	}
)`
	overflow: hidden;

	${({ fluid }) =>
		fluid &&
		css`
			max-width: 100%;
			height: auto;
		`}
	${({ width }) =>
		width &&
		css`
			width: ${width}px;
		`}
	${({ widthRaw }) =>
		widthRaw &&
		css`
			width: ${widthRaw};
		`}
	${({ height }) =>
		height &&
		css`
			height: ${height}px;
		`}
	${({ circle }) =>
		circle &&
		css`
			border-radius: 50%;
		`}
`;

Image.defaultProps = {
	fluid: false,
	width: false,
	height: false,
	circle: false,
	vaMiddle: false,
};

Image.propTypes = {
	fluid: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	circle: PropTypes.bool,
	vaMiddle: PropTypes.bool,
};

Image.displayName = "Image";

export default Image;
