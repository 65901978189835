// ** Third Party Components
import { useTranslation } from "react-i18next";

// #####################################################

const useVipStatusText = (status) => {
	const { t } = useTranslation("common");

	// #####################################################

	switch (status) {
		case 0:
		case 1:
			return t("common:statusVip.1");
		case 2:
			return t("common:statusVip.2");
		case 3:
		case 4:
			return t("common:statusVip.4");
		case 5:
			return t("common:statusVip.5");
	}
};

export default useVipStatusText;

// #####################################################
